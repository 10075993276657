/*!****************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/_childs/bruecks/variables.scss ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************************/
/* purgecss start ignore */
:root {
  --color-primary: #213c42;
  --color-primary-rgba: rgb(33, 60, 66);
  --color-primary-alt: #1d3d44;
  --color-primary-light: #3b626b;
  --color-primary-lightest: #4b7a85;
  --color-secondary: #153f49;
  --color-tertiary: #173238;
  --color-tertiary-alt: #0e2226;
  --color-font-light: #6d99a4;
  --color-font-dark: #025366;
  --color-font-highlight: #4e1d95;
  --color-pink: #ff7041;
  --color-light-green: #ff7041;
  --color-primary-darker: #000007;
  --color-tertiary-darker: #c9bb9b;
  --color-secondary-darker: #dea83f;
  --color-light-green-darker: #001f27;
  --color-pink-darker: #2f011d;
  --color-heading: #ffffff;
  --color-body: #acacac;
  --color-dark: #202020;
  --color-darker: rgba(20, 20, 20, 0.7);
  --color-darkest: rgba(0, 0, 0, 0.3);
  --color-black: #000000;
  --color-blacker: rgba(15, 15, 15, 0);
  --color-blackest: #101010;
  --color-blacker-alpha: rgba(15, 15, 15, 0.7);
  --color-border: hsla(0,0%,100%,0.1);
  --color-lessdark: #1e1e1e;
  --color-gray: #65676B;
  --color-midgray: #878787;
  --color-light: #f5f8fa;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;
  --color-white: #ffffff;
  --color-green: #02d256;
  --color-success: #008c39;
  --color-danger: #cf0002;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;
  --border-width: 2px;
  --radius-small: 6px;
  --radius: 10px;
  --radius-big: 16px;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --shadow-primary: 0 0 20px 5px rgba(33, 60, 66,0.2);
  --shadow-light: 1px 1px 6px rgba(0,0,0,0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0,0,0,0.01);
  --transition: 0.3s;
  --transition-transform: transform .65s cubic-bezier(.23,1,.32,1);
  --font-primary: "Poppins Regular", sans-serif;
  --secondary-font: "Roboto", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";
  --font-poppins-regular: "Poppins Regular";
  --font-poppins-bold: "Poppins Bold";
  --font-poppins-bolder: "Poppins Bolder";
  --font-poppins-extrabold: "Poppins ExtraBold";
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;
  --line-height-b1: 1.67;
  --line-height-b2: 1.7;
  --line-height-b3: 1.6;
  --line-height-b4: 1.3;
  --h1: 56px;
  --h2: 44px;
  --h3: 36px;
  --h4: 24px;
  --h5: 18px;
  --h6: 16px;
  --src-promo-melt: url(/build/images/bruecklyn-melt-vodka.3c88ef13.jpg);
  --src-promo-gin: url(/build/images/bruecklyn-gin.946e8c0c.jpg);
  --src-promo-shots: url(/build/images/bruecklyn-shots.eab9f4a3.jpg);
}

/* purgecss end ignore */
/*!************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/_childs/bruecks/fonts.scss ***!
  \************************************************************************************************************************************************************************************************************************************************************************************************************************/
@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  src: url(/build/fonts/Poppins-Regular.35d26b78.ttf);
}
@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 600;
  src: url(/build/fonts/Poppins-SemiBold.ac8d04b6.ttf);
}
@font-face {
  font-family: "Poppins Bolder";
  font-style: normal;
  font-weight: 600;
  src: url(/build/fonts/Poppins-Bold.cdb29a5d.ttf);
}
@font-face {
  font-family: "Poppins ExtraBold";
  font-style: normal;
  font-weight: 600;
  src: url(/build/fonts/Poppins-ExtraBold.01849ee0.ttf);
}
/*!**************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/_childs/bruecks/bruecks.scss ***!
  \**************************************************************************************************************************************************************************************************************************************************************************************************************************/
@charset "UTF-8";
/* purgecss start ignore */
h1, .h1, h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font-poppins-bold);
}

a {
  color: var(--color-font-light);
}

header.header-default.header-transparent {
  background: rgba(0, 0, 0, 0.3) !important;
}

body.active-light-mode .rn-header.sticky, body.active-light-mode .rn-header.header-not-transparent {
  background: #000 !important;
}

body.active-light-mode header.header-transparent.header-sticky.sticky .logo a img.logo-dark {
  display: none;
}

body.active-light-mode header.header-transparent.header-sticky.sticky .logo a img.logo-light {
  display: inline;
}

.mainmenu-nav .mainmenu > li > a {
  text-transform: uppercase;
  font-family: var(--font-poppins-extrabold);
  color: #fff !important;
}

.mainmenu-nav .mainmenu > li > a:hover {
  background: var(--color-primary) !important;
  color: #fff !important;
}

.mainmenu-nav .mainmenu > li > a.active {
  color: var(--color-primary-lightest) !important;
}

.mainmenu-nav .logo {
  margin: 0 20px;
}

.mainmenu-nav .logo img {
  max-height: 37px !important;
}

.header-left-align .mainmenu-nav {
  margin-left: 0;
}

@media (max-width: 991px) {
  header .mainmenu-nav ul.mainmenu.nav li {
    display: none;
  }
}
@media (max-width: 600px) {
  #userWidgetShop {
    display: none;
  }
}
@media (max-width: 440px) {
  .mainmenu-nav .logo {
    display: none;
  }
}
header .header-row {
  height: 80px;
}

header .login-section {
  position: absolute;
  right: 100px;
  width: auto;
}

header .social-section {
  position: absolute;
  right: 5px;
  width: auto;
}

.login-section, .header-buttons {
  position: relative;
}

#userWidget, #userWidgetShop {
  background: none;
  color: #fff;
  transition: all 0.3s ease;
  margin-right: 5px;
  border: 1px solid #515151;
  border-radius: 25px !important;
  font-family: var(--font-poppins-extrabold);
  transition: all 0.4 ease;
}

#userWidget:hover, #userWidgetShop:hover {
  color: var(--color-primary-lightest);
  transform: scale(0.9);
}

#userWidget:hover {
  border-color: var(--color-primary-lightest);
}

#userWidget svg, #userWidget #user-widget {
  display: inline-block;
  width: auto;
}

#userWidgetShop {
  position: relative;
  min-width: 60px;
  height: 40px;
  float: left;
  border-radius: 8px;
  padding: 7px 15px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background: #fff;
  color: #000;
}
#userWidgetShop a {
  color: #000;
}

#share-btn {
  margin: 0 5px;
}

body.active-light-mode .popup-mobile-menu .inner {
  background: #000;
}

.shoplink {
  padding: 5px 30px !important;
  border: 2px solid #fff;
  border-radius: 20px;
  margin-top: 15px;
  color: #000;
}

body.active-light-mode .popup-mobile-menu .mainmenu li a {
  color: #fff;
  font-family: var(--font-poppins-bolder);
}

body.active-light-mode .popup-mobile-menu .mainmenu li a.active {
  color: var(--color-primary-lightest) !important;
}

body.active-light-mode .hamberger-button {
  height: 36px;
  width: 36px;
  background: transparent;
  border: 1px solid #515151;
  color: #fff;
  transition: all 0.4 ease;
}

body.active-light-mode .hamberger-button:hover {
  color: var(--color-primary-lightest);
  border-color: var(--color-primary-lightest);
  transform: scale(0.9);
}

body.active-light-mode .rn-slick-arrow button.slick-arrow {
  border: none;
}

.slider-area.rn-slick-arrow button.slick-arrow::before {
  top: 5px;
}

.rn-slick-arrow button.slick-arrow.prev-arrow::before, .rn-slick-arrow button.slick-arrow.next-arrow::before {
  color: #fff;
  font-size: 74px;
}

.rn-slick-arrow button.slick-arrow.prev-arrow::before {
  content: "˂";
}

.rn-slick-arrow button.slick-arrow.next-arrow::before {
  content: "˃";
}

.rn-slick-arrow button.slick-arrow:hover {
  background: rgba(33, 60, 66, 0.4);
}

[data-black-overlay]::before {
  background-color: #fff;
}

@media (max-width: 1350px) {
  header .login-section {
    left: 20px;
    right: unset;
  }
  #userWidget, #userWidgetShop {
    margin-right: 0;
    padding: 6px 10px;
    font-size: 12px;
    min-width: auto;
    height: 36px;
  }
  #userWidgetShop {
    padding: 7px 10px;
  }
  #share-btn {
    width: 36px;
    height: 36px;
  }
}
@media (max-width: 1100px) {
  header .login-section {
    left: 5px;
  }
  #userWidget, #userWidgetShop {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mainmenu-nav .mainmenu > li > a {
    padding: 0 8px;
    font-size: 14px;
  }
}
@media (max-width: 782px) {
  .hamberger-button {
    margin-left: 5px;
  }
}
header .social-section a {
  color: #fff !important;
  transition: all 0.3s ease;
}

header .social-section a:hover {
  color: var(--color-primary-lightest) !important;
}

header .social-section a svg {
  margin: 0 4px;
  height: 13px;
}

.main-content {
  background-color: #fff;
  z-index: 1;
  position: relative;
  margin-bottom: 640px;
}

.galeries-area .rn-card {
  cursor: pointer;
}

.galeries-area .rn-card .inner .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.galeries-area .rn-card .inner .thumbnail {
  aspect-ratio: 1.91;
}

.galeries-area .rn-card .inner .content ul.rn-meta-list {
  background: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}

.galeries-area .rn-card .inner .content ul.rn-meta-list li.separator {
  margin: 0 3px;
}

.galeries-area .rn-card .inner .content ul.rn-meta-list li {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
}

@media screen and (max-width: 1200px) and (min-width: 991px) {
  .galeries-area .rn-card .inner .content ul.rn-meta-list li {
    font-size: 12px;
  }
  .galeries-area .rn-card .inner .content h4.title {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .galeries-area .rn-card .inner .content ul.rn-meta-list li {
    font-size: 12px;
  }
  .galeries-area .rn-card .inner .content h4.title {
    font-size: 14px;
  }
}
.galeries-area .rn-card .inner .content h4.title {
  text-align: center;
  color: #fff;
  text-shadow: 3px 2px 2px rgba(0, 0, 0, 0.73);
}

.jobs-area .service.service__style--2, .mieten-area .service.service__style--2 {
  padding: 0 0 10px 0;
}

.jobs-area .service.service__style--2:hover, .mieten-area .service.service__style--2:hover {
  box-shadow: 0 0 39px rgba(75, 122, 133, 0.1);
}

.jobs-area .service.service__style--2 .image img, .mieten-area .service.service__style--2 .image img {
  max-height: unset;
}

.jobs-area .service.service__style--2 ul, .mieten-area .service.service__style--2 ul {
  margin-left: 20px;
}

.mieten-pdf-area {
  background: #0c1e22;
}

.mieten-pdf-area h4.subtitle .theme-gradient {
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
}

body.active-light-mode .mieten-pdf-area p {
  color: #fff;
}

.about-img-area {
  background: #0c1e22;
}

.about-img-area .about-img {
  min-height: 500px;
  padding: 1rem !important;
  transition: all 0.4s ease;
}

.about-img-area .about-img:hover {
  transform: scale(1.02);
}

@media (max-width: 991px) {
  .about-area p.b1 {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .about-img-area .about-img {
    min-height: 350px;
  }
}
@media (max-width: 550px) {
  .about-img-area .about-img {
    min-height: 200px;
  }
}
.galeries-subpage-area .rn-card .inner .thumbnail a {
  aspect-ratio: 20/9;
  -o-object-fit: cover;
     object-fit: cover;
}

body.active-light-mode .rn-card {
  background: var(--color-primary-light);
  transition: all 0.4s ease;
  color: #b7b7b7;
}

body.active-light-mode .rn-card:hover {
  background: var(--color-primary);
  transform: scale(1.03);
}

body.active-light-mode .modal-dialog .rn-card {
  text-align: center;
}

body.active-light-mode .modal-dialog .rn-card.lounge-card, body.active-light-mode .modal-dialog .rn-card.event-card {
  border: 3px solid var(--color-green) !important;
}

body.active-light-mode .modal-dialog .rn-card.lounge-card.lounge-status-blocked, body.active-light-mode .modal-dialog .rn-card.event-card.lounge-status-blocked {
  border: 3px solid var(--color-danger) !important;
}

body.active-light-mode .rn-card ul li, body.active-light-mode .rn-card h4, body.active-light-mode .rn-card .h4, body.active-light-mode .rn-address .icon, body.active-light-mode .rn-address h4 {
  color: #fff;
}

body.active-light-mode .rn-address p, body.active-light-mode .rn-address .inner p a {
  color: #b7b7b7;
}

body.active-light-mode .rn-address {
  background: var(--color-primary-light);
}

body.active-light-mode .bg-color-blackest {
  background: var(--color-primary-light) !important;
}

body.active-light-mode .service.service__style--1 .icon, body.active-light-mode .service.service__style--1 .content h4 {
  color: #fff;
}

body.active-light-mode .service.service__style--1 .content .color-gray {
  color: #CECECE !important;
}

body.active-light-mode .service-wrapper > [class*=col]:nth-child(2) .service.service__style--1 .icon, body.active-light-mode .service-wrapper > [class*=col]:nth-child(3) .service.service__style--1 .icon {
  color: #fff;
}

body.active-light-mode .rn-service-area p.event-details {
  color: #fff;
}

.shop-footer {
  background-color: var(--color-success);
}

footer.rn-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

body.active-light-mode .footer-style-default, body.active-light-mode .copyright-style-one {
  background: #000;
}

body.active-light-mode .logo-dark {
  display: inline;
}

body.active-light-mode footer.rn-footer h4 {
  color: #aaa !important;
}

#cropper-modal {
  background: rgba(0, 0, 0, 0.6);
}

.area-filter .dropdown-toggle[data-v-afa6fd7c] {
  background: #0b0b0b;
}

@media (max-width: 768px) {
  .rn-footer-widget {
    text-align: center !important;
  }
  .widget-menu-top {
    margin-bottom: 60px;
  }
}
@media (max-width: 400px) {
  .box-distance {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .service.service__style--1 {
    padding: 14px 7px !important;
  }
}
/* ÜBERSCHRIFTEN CSS-Styles Ende */
.section-title h4.subtitle, .section-title h3.subtitle, .section-title h2.title, .rn-sub-badge, .rn-sub-badge h1, h1.display-one, h2.display-one {
  background: none;
  box-shadow: none;
}

.section-title h4.subtitle, body.active-light-mode .section-title h4.subtitle, .section-title h3.subtitle, body.active-light-mode .section-title h3.subtitle, .rn-sub-badge, .rn-sub-badge h1, body.active-light-mode .rn-sub-badge {
  line-height: 58px;
  font-size: 56px;
  background: none;
}

.section-title h2.title, h1.display-one, h2.display-one {
  line-height: 34px;
  font-size: 32px;
}

h4.subtitle .theme-gradient {
  background: var(--color-primary);
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
}

.rn-sub-badge h1.theme-gradient, .rn-sub-badge .theme-gradient {
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
}

@media (max-width: 780px) {
  .section-title h4.subtitle, .section-title h3.subtitle, .rn-sub-badge, .rn-sub-badge h1 {
    line-height: 24px;
    font-size: 22px;
  }
  .section-title h2.title, h1.display-one, h2.display-one {
    line-height: 30px;
    font-size: 26px;
  }
}
/* ÜBERSCHRIFTEN CSS-Styles Ende */
/* BUTTON CSS-Styles */
body a.btn-default, button.btn-default.btn-large, div .modal-dialog .modal-content .modal-body button[type=submit].btn, body #user .btn, #user #UserDetails .btn[type=submit], #userWidgetMobile .login-btn, #my_switcher, .checkout-event .btn, .checkout-event2 .btn {
  border-radius: 30px;
  background-color: var(--color-tertiary);
  background-image: none !important;
  border: 1px solid var(--color-tertiary) !important;
}

body a.btn-default, button.btn-default.btn-large, body #user .btn, #userWidgetMobile .login-btn, .hamberger-button, .checkout-event .btn, .checkout-event2 .btn {
  padding-left: 20px;
  padding-right: 20px;
}

body .view-event-site a.btn-default {
  border: none !important;
  background-image: linear-gradient(to right, rgb(252, 53, 76) 0%, rgb(54, 109, 193) 51%, rgb(252, 53, 76) 100%) !important;
}

body .view-event-site a.btn-default.btn-large {
  font-size: 24px;
}

.event-tix-lounges-links .btn-read-more {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 2px 12px;
  margin-right: 15px;
  background: rgb(10, 191, 188);
  background-size: 200% auto;
  transition: 0.3s all ease;
}

.event-tix-lounges-links .btn-read-more:hover {
  background: rgb(252, 53, 76);
}

body.active-light-mode .event-area .rn-card .event-tix-lounges-links .btn-read-more span {
  color: #FFF !important;
}

body.active-light-mode .event-area .rn-card:hover .event-tix-lounges-links .btn-read-more span, body.active-light-mode .event-area .rn-card:hover .event-tix-lounges-links .btn-read-more span:hover {
  color: #fff !important;
}

body.active-light-mode .event-area .rn-card .event-tix-lounges-links .btn-read-more span::before {
  content: none;
}

body.active-light-mode a.btn-default, body.active-light-mode button.btn-default.btn-large, body.active-light-mode div .modal-dialog .modal-content .modal-body button[type=submit].btn, body.active-light-mode #user .btn, body.active-light-mode #userWidgetMobile .login-btn, body.active-light-mode .hamberger-button, body.active-light-mode #my_switcher, body.active-light-mode .checkout-event .btn, body.active-light-mode .checkout-event2 .btn {
  border: 1px solid var(--color-tertiary);
}

body.active-light-mode .header-not-transparent {
  background: rgba(253, 244, 236, 0.9) !important;
}

#userWidgetMobile .login-btn:hover {
  box-shadow: none;
}

#userWidgetMobile .bg-theme-gradient, #userWidgetMobile .side-menu-user-card.bg-theme-gradient {
  background: none;
}

#userWidgetMobile .side-menu-user-card.justify-content-center {
  background: var(--color-tertiary);
}

#userWidgetMobile .side-menu-user-name {
  width: 100%;
}

body.active-light-mode .hamberger-button {
  border-radius: 100%;
  padding: 0;
  border: 1px solid #515151;
}
body.active-light-mode .hamberger-button:before, body.active-light-mode .hamberger-button:after {
  border-radius: 100%;
}

body.active-light-mode a.btn-default.btn-border, body.active-light-mode button.btn-default.btn-border {
  color: #fff;
}

/* BUTTON CSS-Styles Ende */
/* ALLGEMEINE CSS-Styles für alle Webseiten */
h2, h3, h4 {
  margin-top: 20px;
}

.header-default {
  border: none;
}

hr.rbt-separator {
  display: none;
}

.rn-gradient-circle {
  display: none;
}

#user-info-form button[type=button].btn, .checkout button[type=button].btn.bg-white {
  background: var(--color-primary) !important;
  clip-path: none;
  border-radius: 0 10px 10px 0 !important;
  color: #fff !important;
}

#user-info-form button[type=button].btn::before {
  content: none;
}

a.btn {
  border: none;
}

.rwt-gallery-area .text-danger {
  text-align: center;
}

.rn-accordion-style .card .card-header {
  margin-top: 0;
}

#register-form .btn, #register-form button.btn-default.btn-border, #register-form a.btn-default.btn-border {
  height: 40px;
  line-height: 36px;
  border-radius: 10px;
  font-size: 16px;
  padding: 0 20px;
  background-image: none !important;
  background: none;
  box-shadow: none;
  text-shadow: none;
  border: 2px solid var(--color-border) !important;
  transition: all 0.5s ease !important;
}

#register-form .btn:hover, #register-form button.btn-default.btn-border:hover, #register-form a.btn-default.btn-border:hover {
  background-color: transparent !important;
  border-color: var(--color-secondary) !important;
  color: var(--color-secondary) !important;
  filter: none;
}

body.active-light-mode #register-form .btn, body.active-light-mode #register-form button.btn-default.btn-border, body.active-light-mode #register-form a.btn-default.btn-border {
  color: #a0a0a0 !important;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
}

body.active-light-mode #register-form .btn:hover, body.active-light-mode #register-form button.btn-default.btn-border:hover, body.active-light-mode #register-form a.btn-default.btn-border:hover {
  box-shadow: none;
  border-color: var(--color-secondary) !important;
}

div .modal-dialog .modal-content .modal-body .checkout .btn {
  color: #5e5c5c;
}

/* ALLGEMEINE CSS-Styles Ende */
/* APP-Promotion Start */
.app-promotion {
  background: rgba(2, 2, 2, 0.6);
}

body.active-light-mode .app-promotion {
  background: transparent;
}

body.active-light-mode .app-promotion .app-promotion-overlay {
  content: " ";
  background-color: rgba(255, 255, 255, 0.4);
}

.app-promotion .disconame {
  background: rgba(38, 38, 38, 0.4);
}

.app-promotion .smartphone {
  background: rgba(38, 38, 38, 0.8);
}

body.active-light-mode .app-promotion .disconame {
  background: var(--color-primary-lightest);
  color: #fff;
}

body.active-light-mode .app-promotion .smartphone {
  background: var(--color-primary);
  color: #fff;
}

.app-promotion h3.title {
  font-size: 22px;
  margin: 11px 0 7px 0;
  color: #9dc5ce;
}

.app-promotion h3.title strong {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
}

.app-promotion p {
  font-size: 28px;
  line-height: 55px;
  margin: 0;
}

.app-promotion p.left {
  color: var(--color-font-light);
}

body.active-light-mode .app-promotion p.left {
  color: var(--color-font-light);
}

.app-promotion p.right {
  text-align: right;
  color: var(--color-font-dark);
}

body.active-light-mode .app-promotion p.right {
  color: var(--color-font-dark);
}

.app-promotion p strong {
  color: var(--color-font-highlight);
}

@media (max-width: 768px) {
  .app-promotion p, .app-promotion p.right {
    text-align: center;
  }
}
@media (max-width: 1400px) {
  .app-promotion p {
    font-size: 26px;
  }
}
@media (max-width: 1200px) {
  .app-promotion p {
    font-size: 21px;
    line-height: 40px;
  }
}
@media (max-width: 991px) {
  .main-content {
    margin-bottom: 780px;
  }
  .app-promotion .thumbnail {
    margin-top: 50px;
  }
  .app-promotion p {
    font-size: 28px;
    line-height: 55px;
  }
}
@media (max-width: 768px) {
  .main-content {
    margin-bottom: 0;
  }
  footer.rn-footer {
    position: relative;
  }
}
/* APP-Promotion Ende */
body.active-light-mode .event-area .rn-card {
  border: none;
  --initialBgColor: var(--color-primary-lightest);
  --slideBgColor: var(--color-primary);
  background-image: linear-gradient(90deg, var(--initialBgColor) 0%, var(--initialBgColor) 50%, var(--slideBgColor) 50%, var(--slideBgColor) 100%);
  background-size: 200%;
  transition: background-position 0.3s cubic-bezier(0.47, 0.1, 1, 0.63), color 0.2s linear transform 0.3s ease;
  transition-delay: 0s, 0.15s;
}
body.active-light-mode .event-area .rn-card .event-date-cal {
  transition: background-color 0.3s ease;
}
body.active-light-mode .event-area .rn-card .event-date-cal .event-date-cal-month {
  transition: all 0.3s ease;
}
body.active-light-mode .event-area .rn-card .event-info h4.title {
  font-family: var(--font-poppins-extrabold);
}
body.active-light-mode .event-area .rn-card .event-description a {
  color: #fff !important;
  transition: all 0.3s ease;
  font-family: var(--font-poppins-extrabold);
}
body.active-light-mode .event-area .rn-card .btn-read-more span {
  color: #165766 !important;
  transition: all 0.3s ease;
  font-family: var(--font-poppins-extrabold);
}
body.active-light-mode .event-area .rn-card .btn-read-more span::before {
  background: #fff;
}
body.active-light-mode .event-area .rn-card .btn-read-more span::after {
  background: transparent;
}

body.active-light-mode .event-area .rn-card:hover {
  box-shadow: 10px 50px 42px -52px rgba(0, 0, 0, 0.3);
  transform: scale(1.04);
  cursor: pointer;
  background-position: -100% 100%;
}
body.active-light-mode .event-area .rn-card:hover .event-date-cal {
  background-color: rgba(0, 0, 0, 0.3);
}
body.active-light-mode .event-area .rn-card:hover .event-date-cal .event-date-cal-month {
  color: #f00 !important;
}
body.active-light-mode .event-area .rn-card:hover .event-description a:hover {
  color: var(--color-primary-lightest) !important;
  transition: all 0.3s ease;
}
body.active-light-mode .event-area .rn-card:hover .btn-read-more span, body.active-light-mode .event-area .rn-card:hover .btn-read-more span:hover {
  color: var(--color-primary-lightest) !important;
  transition: all 0.3s ease;
}
body.active-light-mode .event-area .rn-card:hover .btn-read-more span:hover {
  color: #fff !important;
}
body.active-light-mode .event-area .rn-card:hover .btn-read-more span::after {
  background: transparent;
}

body.active-light-mode .event-area h4 {
  color: #fff;
}

body.active-light-mode .event-area .rn-card:hover h4, body.active-light-mode .event-area .rn-card:hover h4 a {
  color: var(--color-font-light) !important;
}

.event-date-cal {
  text-align: center;
  line-height: 1.8em;
}
.event-date-cal .event-date-cal-weekday {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: #c2c2c2;
}
.event-date-cal .event-date-cal-day {
  font-size: 46px !important;
  line-height: 46px;
  color: #fff;
  font-family: var(--font-poppins-extrabold);
}
.event-date-cal .event-date-cal-month {
  color: #013fd3 !important;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.event-info {
  padding: 20px 30px;
}

.event-description {
  color: #e1e1e1;
}

.event-img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.91;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
}

body.active-light-mode .event-date-cal .event-date-cal-day {
  color: #fff !important;
}

body.active-light-mode .event-box {
  border-bottom: 1px solid #ececec;
}
body.active-light-mode .event-box:hover {
  background: rgba(0, 0, 0, 0.1);
}

body.active-light-mode .event-details .title, body.active-light-mode .event-details .event-tix-lounges-links .btn-read-more span, body.active-light-mode .event-details .event-date-cal-day {
  color: var(--color-primary);
}
body.active-light-mode .event-details .event-date-cal-weekday {
  color: var(--color-body);
}

body.active-light-mode .event-box .event-snippet .event-info .event-description {
  color: #65676b;
}

body.active-light-mode .btn-read-more span {
  color: var(--color-font-dark);
}

@media (max-width: 768px) {
  .event-description {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 460px) {
  .event-description {
    font-size: 12px;
    line-height: 20px;
  }
  .event-info {
    padding: 20px 15px;
  }
}
@media (max-width: 360px) {
  .event-description {
    font-size: 10px;
    line-height: 16px;
  }
}
#promo .promo-col {
  height: 1100px !important;
}

#promo .promo-col.promo-bg {
  height: 1200px !important;
}

#promo .promo-col .promo-bg, #promo .promo-col.promo-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

#promo .promo-col.promo-bg .jarallax-container div {
  background-color: rgba(12, 19, 31, 0.455);
  background-blend-mode: darken;
}

#promo .promo-col-text p {
  margin: 0 0 15px 0;
  font-size: 20px;
  line-height: 24px;
}

#promo .promo-col-text h1 {
  color: var(--color-primary);
  text-transform: uppercase;
}

#promo .promo-col-text p strong {
  font-family: var(--font-poppins-bold);
}

#promo .promo-col.promo-bg.promo-col-text h1, #promo .promo-col.promo-bg.promo-col-text p {
  color: #fff;
}

@media (max-width: 600px) {
  .slider-area #slick-slide00.height-950 {
    height: auto;
  }
}
.area-filter .dropdown-toggle[data-v-6fc1f84e] {
  color: #2d2d2d;
}

#share-btn {
  display: none;
}

/* purgecss end ignore */
